import { EngineStore } from '~/stores/engine'
import { ProfileStore } from '~/stores/profile'
import type { EnrollRequest } from '~/generated/api-clients-generated'
import { PlanType } from '~/generated/api-clients-generated'
import type { QuotedPlanVm } from '~/models/quoting/QuotedPlan'

export class DevModeHelpers {
  static use() {
    const { flag } = FeatureFlags.use()
    const devMode = flag<boolean>('dev-mode')
    const runtimeConfig = useRuntimeConfig()
    const devModeEnabled = computed(() => !!devMode.value && isQaOrDevelopment.value)

    const isQaOrDevelopment = computed(
      () =>
        runtimeConfig.public.sentryEnv == 'QA' || runtimeConfig.public.sentryEnv == 'Development'
    )

    const isCarrier = computed(() => runtimeConfig.public.sentryEnv == 'Carrier')

    const engine = EngineStore.use()
    const profile = ProfileStore.use()

    const isOpening = ref(false)
    const openInConnecture = async (externalId: string) => {
      if (!devModeEnabled.value || isOpening.value) return

      isOpening.value = true

      const request: EnrollRequest = {
        engineParams: engine.params,
        planId: externalId!,
        title: '',
        firstName: profile.firstName ?? 'DRX_TEST_FIRSTNAME',
        lastName: profile.lastName ?? 'DRX_TEST_LASTNAME',
        middleInitial: profile.middleName ?? '',
        phoneNumber: profile.mobilePhone?.number ?? '1231231234',
        email: profile.emailAddress?.address ?? 'DRXTEST@DRXTEST.com',
        mbi: profile.medicareCard?.medicareNumber ?? '1EG4TE5MK73',
        profileId: profile.id!
      }
      try {
        const response = await PxApi.use().createEnrollmentClient().start(request)
        const url = `${response.url}?${response.parameters}`
        console.log('URL: ', url)

        window.open(url, '_blank')
      } catch (err) {
        console.error('Failed to open app in connecture: ', err)
      } finally {
        isOpening.value = false
      }
    }

    const HEATCarriers = [
      'anthem',
      'cigna',
      'floridablue',
      'hcsc',
      'highmark',
      'uhc',
      'upmc',
      'wellcare',
      'zing',
      'ibx',
      'medigold',
      'bcbsnc'
    ]
    const isHeatEnabled = (quote: QuotedPlanVm) => {
      return (
        quote.type != PlanType.GAP &&
        HEATCarriers.includes(quote.details.carrierKey?.toLowerCase() ?? '')
      )
    }

    return {
      devModeEnabled,
      isQaOrDevelopment,
      isCarrier,
      openInConnecture,
      isHeatEnabled
    }
  }
}
